import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Column, Row} from "simple-flexbox";
import {css, StyleSheet} from "aphrodite";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import moment from "moment/moment";
import DetailSubTitleComponent from "../../components/detail/DetailSubTitleComponent";
import {
    getRegisteredList,
    getRegisteredListError,
    getRegisteredListPending
} from "../../reducers/registrasi_member/regist_member_reducer";
import {registeredList} from "../../actions/registrasi_member/regist_member_action";

class DetailPendidikan extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedItem: "profil", is_subspecialist: false };
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        this.props.registeredList(
            {
                id: id
            }
        )
    }

    render() {
        const {registeredList_data} = this.props;
        const data = registeredList_data?.data[0];
        return (
            <Row className={css(styles.rightDetailContainer)}>
                <Column className={css(styles.rightDetailColumn)}>
                    {this.state.is_subspecialist === false ? (
                        <>
                            <DetailTitleComponent title="Pendidikan"/>
                            <DetailSubTitleComponent title="Jenis Pendidikan"/>
                            <DetailContentComponent label="Jenis Pendidikan" value={data?.is_subspecialist ? "Subspesialis" : "Spesialis"} />
                            <DetailSubTitleComponent title="Data Pendidikan"/>
                            <DetailContentComponent label="Asal FK" value={"FK " + data?.university_before_sp1_data.name}/>
                            <DetailContentComponent label="Fakultas Kedokteran" value={"FK " + data?.university_id_sp1_data.name}/>
                            <DetailContentComponent label="Tahun Masuk Ujian" value={data?.period_selection_year_sp1}/>
                            <DetailContentComponent label="Tahun Lulus" value={data?.graduated_year_sp1}/>
                            <DetailContentComponent label="No Kolegium" value={data?.collegium_no_sp1}/>
                            <DetailSubTitleComponent title="Indeks Prestasi"/>
                            <DetailContentComponent label="IP S1" value={data?.ip_s1_sp1}/>
                            <DetailContentComponent label="IP Profesi" value={data?.ip_profesi_sp1}/>
                            <DetailContentComponent label="IP Kumulatif" value={data?.ip_cumulation_sp1}/>
                        </>
                    ) : (
                        <>
                            <DetailSubTitleComponent title="Jenis Pendidikan"/>
                            <DetailContentComponent label="Jenis Pendidikan" value={data?.is_subspecialist ? "Subspesialis" : "Spesialis"}/>
                            <DetailSubTitleComponent title="Pendidikan Spesialis"/>
                            <DetailContentComponent label="Asal FK" value={"FK " + data?.university_before_sp1_data?.name}/>
                            <DetailContentComponent label="Fakultas Kedokteran" value={"FK " + data?.university_id_sp1_data?.name}/>
                            <DetailContentComponent label="Tahun Masuk Ujian" value={data?.period_selection_year_sp1}/>
                            <DetailContentComponent label="Tahun Lulus" value={data?.graduated_year_sp1}/>
                            <DetailContentComponent label="No Kolegium" value={data?.collegium_no_sp1}/>
                            <DetailSubTitleComponent title="Indeks Prestasi"/>
                            <DetailContentComponent label="IP S1" value={data?.ip_s1_sp1}/>
                            <DetailContentComponent label="IP Profesi" value={data?.ip_profesi_sp1}/>
                            <DetailContentComponent label="IP Kumulatif" value={data?.ip_cumulation_sp1}/>
                            {/*subSpecialist*/}
                            <DetailSubTitleComponent title="Pendidikan Subspesialis"/>
                            <DetailContentComponent label="Asal FK" value={"FK " + data?.university_before_sp2_data?.name}/>
                            <DetailContentComponent label="Fakultas Kedokteran" value={"FK " + data?.university_id_sp2_data?.name}/>
                            <DetailContentComponent label="Tahun Masuk Ujian" value={data?.period_selection_year_sp2}/>
                            <DetailContentComponent label="Tahun Lulus" value={data?.period_selection_year_sp2}/>
                            <DetailContentComponent label="No Kolegium" value={data?.collegium_no_sp2}/>
                            <DetailSubTitleComponent title="Indeks Prestasi"/>
                            <DetailContentComponent label="IP S1" value={data?.ip_s1_sp2}/>
                            <DetailContentComponent label="IP Profesi" value={data?.ip_profesi_sp2}/>
                            <DetailContentComponent label="IP Kumulatif" value={data?.ip_cumulation_sp2}/>
                        </>
                    )}

                </Column>
            </Row>
        );
    }
}

const styles = StyleSheet.create({
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40,
        alignItems: "center",
        justifyContent: "center",
        display: "block"
    },
    rightDetailContainer: {
        padding: 0,
        width: "100%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    leftDetailColumn: {
        justifyContent: "center",
        alignItems: "center"
    },
    changeStatusButton: {
        padding: 0,
        fontSize: 14
    },
    clickableIcon: {
        ":hover": {
            cursor: "pointer"
        }
    }
});

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
        registeredList_data: getRegisteredList(registeredList),
        registeredList_error: getRegisteredListError(registeredList),
    };
}

export default connect(mapStateToProps, { registeredList })(
    withRouter(DetailPendidikan)
);