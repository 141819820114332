import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import UserReject from "../../assets/User Cross.png"

import {registeredMemberApproval} from "../../actions/registrasi_member/regist_member_action";
import {getRegisteredListPending} from "../../reducers/registrasi_member/regist_member_reducer";
import ModalApproval from "./Modal_Approval";

class ModalReject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reject_note: "",
            modalApproval: false,
            isError: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeRejectNote = this.handleChangeRejectNote.bind(this);
    }

    handleSubmit() {
        if(!this.state.reject_note) {
            this.setState({
                isError: true,
            })
        }else{
            this.setState({
                modalApproval: true,
            })
            this.props.onHide();
        }
    }

    handleChangeRejectNote(e) {
        this.setState({reject_note: e.target.value, isError: false });
    }

    render() {
        const { show, onHide, name } = this.props;
        const { modalApproval, reject_note, isError } = this.state;

        return (
            <>
                <ModalApproval
                    name={name}
                    rejectNote={reject_note}
                    show={modalApproval}
                    onHide={() => this.setState({ modalApproval: false })}/>
                <Modal
                    show={show}
                    onHide={onHide}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="md"
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <Row justifyContent="center" flex="flex">
                            <Column
                                vertical="center"
                                horizontal="center"
                                className={css(styles.bodyModal)}
                            >
                                <img src={UserReject} width="80px" alt="logo-usercheck"/>
                                <div style={{fontSize: 24, fontWeight: 700, margin: "30px 0 20px 0"}}>Tolak Registrasi Member</div>
                                <textarea
                                    className={css(styles.rejectNote)}
                                    name="reject_notes"
                                    placeholder="Masukkan alasan penolakan"
                                    onChange={this.handleChangeRejectNote}
                                />
                                {isError && <div className={css(styles.errorMessage)}>
                                    <span style={{color: "red"}}>*</span>
                                    Alasan penolakan wajib diisi
                                </div>}
                            </Column>
                        </Row>
                        <Row
                            justifyContent="center"
                            flex="flex"
                            vertical="center"
                            width="100%"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                variant="contained"
                                className={css(styles.rejectButton)}
                                onClick={() => this.handleSubmit()}
                            >
                                OK
                            </Button>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}



const styles = StyleSheet.create({
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        fontWeight: 500,
        width: "100%",
        color: "#fff",
        backgroundColor: "#F26664",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
    rejectNote: {
        borderRadius: 8,
        border: "1px solid #D5D9E7",
        textAlign: "left",
        fontSize: 18,
        padding: 16 ,
        width: "100%",
        height: "150px"
    },
    errorMessage: {
        fontSize: 12,
        lineHeight: 1.5,
        textAlign: "left !important" ,
    }
});

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
    };
}

export default connect(mapStateToProps, {registeredMemberApproval})(withRouter(ModalReject));
