import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import UserApprove from "../../assets/User Check.png"
import UserReject from "../../assets/User Cross.png"

import {registeredMemberApproval} from "../../actions/registrasi_member/regist_member_action";
import {getRegisteredListPending} from "../../reducers/registrasi_member/regist_member_reducer";

class Modal_Approval extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        const {
            registeredMemberApproval,
            onHide,
            rejectNote
        } = this.props;
        const {
            match: {
                params: { id },
            },
        } = this.props;

        const param = {
            id: id,
            is_approve: rejectNote ? "false" : "true",
            reject_notes: rejectNote ?? "",
        }

        const callback = () => {
            onHide();
            setTimeout(() => {
                this.props.history.push('/registrasi-member');
            }, 1500)
        };

        registeredMemberApproval(param, callback, rejectNote ? "rejected" : "accepted");
    }

    render() {
        const { show, onHide, rejectNote } = this.props;

        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row flex={"flex"} justifyContent="center">
                        <Column
                            vertical="center"
                            horizontal="center"
                            className={css(styles.bodyModal)}
                        >
                            <img src={rejectNote ? UserReject : UserApprove} width="80px" alt="logo-usercheck"/>
                            <div style={{fontSize: 24, fontWeight: 700, margin: "30px 0 20px 0"}}>
                                {rejectNote ? "Tolak" : "Terima"} Registrasi Member
                            </div>
                            <p style={{fontSize: 18, textAlign: "center", color: "#495057"}}>
                                {rejectNote
                                    ? "Apakah Anda yakin akan menolak proses registrasi"
                                    : "Apakah Anda yakin untuk menerima registrasi dari "}
                                calon anggota PABOI dengan nama <b>{this.props.name}</b>?</p>
                        </Column>
                    </Row>
                    <Row
                        flex="flex"
                        justifyContent="center"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            className={rejectNote ? css(styles.rejectButton) : css(styles.approveButton)}
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleSubmit()}
                        >
                            OK
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        fontWeight: 500,
        width: "100%",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        fontWeight: 500,
        width: "100%",
        color: "#fff",
        backgroundColor: "#F26664",
        boxShadow: "none",
    },
    footerModal: {
        width: "500px",
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
    };
}

export default connect(mapStateToProps, {registeredMemberApproval})(withRouter(Modal_Approval));
