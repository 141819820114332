import React from "react";
import { string } from "prop-types";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

function DetailSubTitleComponent(props) {
    const { title, marginBottom } = props;
    return (
        <Row>
                <span className={css(styles.detailTitle)}> {title} </span>
        </Row>
    )
}

DetailSubTitleComponent.propTypes = {
    title: string,
};

const styles = StyleSheet.create({
    detailTitle: {
        textAlign: "center",
        fontSize: 18,
        fontWeight: "semibold",
        color: "#2C2C2C",
        margin: "20px 0 10px 0"
    },
});

export default DetailSubTitleComponent;