import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { universityList } from "../../actions/master-data/university_action";
import {
    getUniversityListPending,
    getUniversityList,
    getUniversityListError,
} from "../../reducers/master-data/university_reducer";
import _ from "lodash";
import {Button} from "react-bootstrap";
import {FilterList} from "@material-ui/icons";
import {registeredList} from "../../actions/registrasi_member/regist_member_action";
import {
    getRegisteredList,
    getRegisteredListError,
    getRegisteredListPending
} from "../../reducers/registrasi_member/regist_member_reducer";

const styles = StyleSheet.create({
    containerTitle: {
        width: "30%",
        margin: "10px 0",
    },
    containerFilters: {
        width: "70%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    containerSearch: {
        width: '12rem',
        margin: 10,
    },
    containerFilter: {
        width: '8rem',
        margin: 10,
    },
    button: {
        backgroundColor: "#F0F3F6",
        borderRadius: 5,
        color: "#4F5867",
        borderColor: "#F0F3F6",
    }
});
class HeaderRegistrasiMember extends Component {
    constructor(props) {
        super(props);
        this.handleChangeNama = this.handleChangeNama.bind(this);
        this.delayedQuery = this.delayedQuery.bind(this);
    }


    delayedQuery = _.debounce((value) => {
        const { handleRefresh } = this.props;
        return handleRefresh(value);
    }, 500);

    handleChangeNama(e) {
        if (e !== null) {
            this.setState({ nama: e.target.value });
            this.delayedQuery({ search: e.target.value });
        } else {
            this.setState({ nama: e.target.value });
            this.delayedQuery({ search: "" });
        }
    }

    render() {
        return (
            <Row
                style={{
                    padding: 20,
                    paddingBottom: 30,
                    backgroundColor: "#F8F9FB",
                    zIndex: 999,
                    width: "100%",
                }}
                horizontal="space-between"
            >
                <div className={css(styles.containerTitle)}>
                    <h4>Database Registrasi Member</h4>
                </div>
                <div className={css(styles.containerFilters)}>
                    <div className={css(styles.containerSearch)}>
                        <InputComponent
                            onChange={this.handleChangeNama}
                            placeholder="Cari Nama"
                            endIcon={SearchIcon}
                            id="nama"
                        />
                    </div>
                    <div className={css(styles.containerFilter)}>
                        <Button size="md" className={css(styles.button)} onClick={this.props.onShowModal}>
                            <span>Filter</span>
                            <FilterList fontSize="medium" style={{paddingLeft: 3}}/>
                        </Button>
                    </div>
                </div>
            </Row>
        );
    }
}

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
        registeredList_data: getRegisteredList(registeredList),
        registeredList_error: getRegisteredListError(registeredList),
    };
}

export default connect(mapStateToProps, { registeredList })(
    HeaderRegistrasiMember
);
