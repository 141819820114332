import {
    REGISTERED_LIST_PENDING,
    REGISTERED_LIST_SUCCESS,
    REGISTERED_LIST_ERROR,
    DETAIL_REGISTERED_MEMBER_SUCCESS,
    DETAIL_REGISTERED_MEMBER_ERROR,
    DETAIL_REGISTERED_MEMBER_PENDING,
} from "../../actions/registrasi_member/regist_member_action";

const initialState = {
    pending: false,
    error: null,
};

export function registeredList(
    state = { ...initialState, registeredList: null },
    action
) {
    switch (action.type) {
        case REGISTERED_LIST_PENDING:
            return {
                ...state,
                pending: true,
            };
        case REGISTERED_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                registeredList: action.data,
            };
        case REGISTERED_LIST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailRegisteredMember(
    state = { ...initialState, detail_registered_member: null },
    action
) {
    switch (action.type) {
        case DETAIL_REGISTERED_MEMBER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_REGISTERED_MEMBER_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_registered_member: action.data,
            };
        case DETAIL_REGISTERED_MEMBER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}


export const getRegisteredListPending = (state) => state.pending;
export const getRegisteredList = (state) => state.registeredList;
export const getRegisteredListError = (state) => state.error;
export const getDetailRegisteredMember = (state) => state.detail_registered_member;
