import React from "react";

export default (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 2 18 18"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity={props.opacity || ""}
            d="M7.87114 15.6045L7.834 15.3073L7.55449 15.1998C7.40101 15.1408 7.25816 15.0706 7.1253 14.9896L7.12464 14.9892C6.97654 14.8992 6.83164 14.8027 6.68992 14.6996L6.46199 14.5339L6.20215 14.643L4.12882 15.5142L2.27909 12.3192L4.07269 10.9609L4.30745 10.7832L4.26581 10.4916C4.25499 10.4159 4.25 10.3457 4.25 10.2807V9.71817C4.25 9.65386 4.25497 9.58394 4.26581 9.50805L4.30745 9.21652L4.07269 9.03873L2.27909 7.68048L4.12882 4.48549L6.20215 5.35663L6.46199 5.46581L6.68992 5.30004C6.82883 5.19901 6.97446 5.104 7.12694 5.01506C7.27571 4.92828 7.4225 4.85505 7.56731 4.79471L7.83515 4.68311L7.87114 4.39519L8.14972 2.1665H11.8503L12.1289 4.39519L12.166 4.69234L12.4455 4.79984C12.599 4.85888 12.7422 4.9291 12.8758 5.01022C13.0234 5.10027 13.1682 5.19686 13.3101 5.30004L13.538 5.46581L13.7978 5.35663L15.8712 4.48549L17.7209 7.68048L15.9273 9.03873L15.6925 9.21652L15.7342 9.50805C15.745 9.58394 15.75 9.65385 15.75 9.71817V10.2807C15.75 10.3197 15.7441 10.3673 15.7276 10.425L15.6335 10.7542L15.9065 10.9609L17.7001 12.3192L15.851 15.513L13.7992 14.6436L13.5388 14.5333L13.3101 14.6996C13.1712 14.8007 13.0255 14.8957 12.8731 14.9846C12.7243 15.0714 12.5775 15.1446 12.4327 15.205L12.1649 15.3166L12.1289 15.6045L11.8503 17.8332H8.14972L7.87114 15.6045ZM7.6127 7.58587L7.61253 7.58605C6.95723 8.24977 6.625 9.06376 6.625 9.99984C6.625 10.9359 6.95723 11.7499 7.61253 12.4136L7.6127 12.4138C8.27186 13.0808 9.09212 13.4165 10.0417 13.4165C10.9798 13.4165 11.7945 13.0791 12.4577 12.4159C13.121 11.7526 13.4583 10.938 13.4583 9.99984C13.4583 9.06172 13.121 8.24703 12.4577 7.58378C11.7945 6.92054 10.9798 6.58317 10.0417 6.58317C9.09212 6.58317 8.27186 6.9189 7.6127 7.58587Z"
            fill={props.fill || "#FFFFFF"}
        />
    </svg>
);
