import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { NavLink } from "react-router-dom";
import LogoComponent from "./LogoComponent";
import { getUser } from "../../helpers/user";
import MenuItemComponent from "./MenuItemComponent";
import IconDashboard from "../../assets/icon-dashboard";
import IconBurger from "../../assets/icon-burger";
import IconMail from "../../assets/icon-mail";
import IconSchool from "../../assets/icon-school";
import IconAccountCircle from "../../assets/icon-account-circle";

import AddBoxIcon from "../../assets/icon-plus";
import PeopleIcon from "../../assets/icon-people";
import RecordVoiceOverIcon from "../../assets/icon-record-people";
import StarsIcon from "../../assets/icon-star";
import SupportIcon from "../../assets/icon-support";
import PaymentIcon from "../../assets/icon-payment";
import DeviceIcon from "../../assets/icon-device";
import EventIcon from "../../assets/icon-event";
import ChampIcon from "../../assets/icon-champ";
import PreferenceIcon from "../../assets/icon-room-preferences";
import ComputerIcon from "../../assets/icon-computer";
import DocumentIcon from "../../assets/icon-document";
import PeopleAddIcon from "../../assets/icon-people-add";
import MonetizationIcon from "../../assets/icon-monetization";
import AccountBalanceIcon from "../../assets/icon-account-balance";
import RedeemIcon from "../../assets/icon-redeem";
import MailIcon from "../../assets/icon-mail";
import AdsIcon from "../../assets/icon-ads";
import IconSetting from "../../assets/icon-setting";

class SidebarComponent extends React.Component {
  state = { expanded: false };

  onItemClicked = (item, parent) => {
    this.props.onChange(item);
  };

  isMobile = () => window.innerWidth <= 768;

  toggleMenu = () =>
    this.setState((prevState) => ({ expanded: !prevState.expanded }));

  renderBurger = () => {
    return (
      <div onClick={this.toggleMenu} className={css(styles.burgerIcon)}>
        <IconBurger />
      </div>
    );
  };

  render() {
    const { expanded } = this.state;
    const isMobile = this.isMobile();
    let role = null;
    const user = getUser();
    if (user) {
      role = user.role_codes[0];
    }

    const isPaidDues = role === 'member' && user?.member_profile?.is_paid_dues === 1;
    return (
      <div style={{ position: "relative" }}>
        <Row
          className={css(styles.mainContainer)}
          breakpoints={{ 768: css(styles.mainContainerMobile) }}
        >
          {isMobile && !expanded && this.renderBurger()}
          <Column
            className={css(styles.container)}
            breakpoints={{
              768: css(
                styles.containerMobile,
                expanded ? styles.show : styles.hide
              ),
            }}
          >
            <LogoComponent />
            <Column className={css(styles.menuItemList)}>
              {/* {  ini admin kolegium} */}
              {role === "admin_kolegium" && (
                <>
                  <NavLink
                    to="/dashboard-admin-kolegium"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Dashboard"
                      icon={IconDashboard}
                      onClick={() =>
                        this.onItemClicked("Dashboard", "dashboard")
                      }
                      active={this.props.selectedItem === "Dashboard"}
                    />
                  </NavLink>
                  <NavLink
                    to="/registrasi"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Registrasi SP 1"
                      icon={PeopleAddIcon}
                      onClick={() => this.onItemClicked("Registrasi SP 1")}
                      active={this.props.selectedItem === "Registrasi SP 1"}
                    />
                  </NavLink>
                  <NavLink
                    to="/registrasi-sp2"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Registrasi SP 2"
                      icon={PeopleAddIcon}
                      onClick={() => this.onItemClicked("Registrasi SP 2")}
                      active={this.props.selectedItem === "Registrasi SP 2"}
                    />
                  </NavLink>
                  <NavLink to="/ppds" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="PPDS 1"
                      icon={IconSchool}
                      onClick={() => this.onItemClicked("PPDS")}
                      active={this.props.selectedItem === "PPDS"}
                    />
                  </NavLink>
                  <NavLink to="/ppds-sp2" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="SP 2"
                      icon={IconSchool}
                      onClick={() => this.onItemClicked("SP 2")}
                      active={this.props.selectedItem === "SP 2"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Member"
                    icon={IconAccountCircle}
                    onClick={() => this.onItemClicked("Member")}
                    active={this.props.selectedItem === "Member"}
                    child={[
                      {
                        icon: PeopleIcon,
                        title: "Profil",
                        link: "/member",
                      },
                      {
                        icon: IconSchool,
                        title: "Pendidikan",
                        link: "/admin/member-education",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Serkom",
                        link: "/member/serkom",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: AddBoxIcon,
                        title: "Serkom",
                        link: "/admin/serkom",
                      },
                    ]}
                  />
                  {/* <NavLink
                    to="/final-paper"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="CMS Final Paper"
                      icon={DocumentIcon}
                      onClick={() => this.onItemClicked("CMS Final Paper")}
                      active={this.props.selectedItem === "CMS Final Paper"}
                    />
                  </NavLink> */}
                </>
              )}
              {/* </NavLink> */}
              {role === "member" && isPaidDues && (
                <>
                  <MenuItemComponent
                    title="Member"
                    icon={IconAccountCircle}
                    onClick={() => this.onItemClicked("Member")}
                    active={this.props.selectedItem === "Member"}
                    child={[
                      {
                        icon: IconAccountCircle,
                        title: "Profile",
                        link: "/member/member-profile/pengantar",
                      },
                      {
                        icon: IconSchool,
                        title: "Pendidikan",
                        link: "/education",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Tempat Praktik",
                        link: "/practice",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "Pelatihan",
                        link: "/training",
                      },
                      {
                        icon: PeopleIcon,
                        title: "Pengalaman",
                        link: "/experience",
                      },
                      {
                        icon: StarsIcon,
                        title: "Penghargaan",
                        link: "/member/member-appreciation",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: IconSchool,
                        title: "SKP",
                        link: "/skp",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Serkom",
                        link: "/serkom",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR",
                        link: "/str",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR KT",
                        link: "/strkt",
                      },
                      {
                        icon: PeopleIcon,
                        title: "SIP",
                        link: "/sip",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="Trans/ Mutasi"
                    icon={PeopleAddIcon}
                    onClick={() => this.onItemClicked("Trans/ Mutasi")}
                    active={this.props.selectedItem === "Trans/ Mutasi"}
                    child={[
                      {
                        icon: PeopleAddIcon,
                        title: "Mutasi Anggota",
                        link: "/member/mutation",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Trans RS",
                        link: "/transrs",
                      },
                    ]}
                  />
                  <NavLink
                    to="/pengajuan-sip"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Rekom SIP"
                      icon={IconMail}
                      onClick={() => this.onItemClicked("Rekom SIP")}
                      active={this.props.selectedItem === "Rekom SIP"}
                    />
                  </NavLink>
                  <NavLink to="/support" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Support"
                      icon={SupportIcon}
                      onClick={() => this.onItemClicked("Support")}
                      active={this.props.selectedItem === "Support"}
                    />
                  </NavLink>
                  <NavLink to="/benefit" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Benefit"
                      icon={RedeemIcon}
                      onClick={() => this.onItemClicked("Benefit")}
                      active={this.props.selectedItem === "Benefit"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Report"
                    icon={DocumentIcon}
                    onClick={() => this.onItemClicked("Report")}
                    active={this.props.selectedItem === "Report"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Iuran Paboi",
                        link: "/member/iuran",
                      },
                      {
                        icon: AccountBalanceIcon,
                        title: "Koperasi",
                        link: "/koperasi",
                      },
                    ]}
                  />
                </>
              )}
               {role === "member" && !isPaidDues && (
                <>
                  <MenuItemComponent
                    title="Member"
                    icon={IconAccountCircle}
                    onClick={() => this.onItemClicked("Member")}
                    active={this.props.selectedItem === "Member"}
                    child={[
                      {
                        icon: IconAccountCircle,
                        title: "Profile",
                        link: "/member/member-profile/pengantar",
                      },
                    ]}
                  />
                </>
              )}
              {role === "root" && (
                <>
                  <NavLink
                    to="/dashboard-admin-pusat"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Dashboard"
                      icon={IconDashboard}
                      onClick={() =>
                        this.onItemClicked("Dashboard", "dashboard")
                      }
                      active={this.props.selectedItem === "Dashboard"}
                    />
                  </NavLink>
                  <NavLink
                      to="/registrasi-member"
                      exact
                      className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                        title="Registrasi Member"
                        icon={IconSetting}
                        onClick={() =>
                            this.onItemClicked("Registrasi Member")
                        }
                        active={this.props.selectedItem === "Registrasi Member"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Member"
                    icon={IconAccountCircle}
                    onClick={() => this.onItemClicked("Member")}
                    active={this.props.selectedItem === "Member"}
                    child={[
                      {
                        icon: PeopleIcon,
                        title: "Profil",
                        link: "/member",
                      },
                      {
                        icon: IconSchool,
                        title: "Pendidikan",
                        link: "/admin/member-education",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Tempat Praktik",
                        link: "/practice",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "Pelatihan",
                        link: "/admin/member-training",
                      },
                      {
                        icon: PeopleIcon,
                        title: "Pengalaman Kerja",
                        link: "/admin/member-experience",
                      },
                      {
                        icon: StarsIcon,
                        title: "Penghargaan",
                        link: "/admin/member-appreciation",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: IconSchool,
                        title: "SKP",
                        link: "/admin-p2kb",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Serkom",
                        link: "/admin/serkom",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR",
                        link: "/admin/str",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR KT",
                        link: "/admin/strkt",
                      },
                      {
                        icon: PeopleIcon,
                        title: "SIP",
                        link: "/admin/sip",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="Trans/ Mutasi"
                    icon={PeopleAddIcon}
                    onClick={() => this.onItemClicked("Trans/ Mutasi")}
                    active={this.props.selectedItem === "Trans/ Mutasi"}
                    child={[
                      {
                        icon: PeopleAddIcon,
                        title: "Mutasi Anggota",
                        link: "/admin/mutation",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Trans RS",
                        link: "/transrs",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="Data Referensi"
                    icon={PreferenceIcon}
                    onClick={() => this.onItemClicked("Data Referensi")}
                    active={this.props.selectedItem === "Data Referensi"}
                    child={[
                      // {
                      //   icon: PeopleIcon,
                      //   title: "Pengguna",
                      //   link: "/pengguna",
                      // },
                      {
                        icon: IconSchool,
                        title: "Universitas",
                        link: "/universitas",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Rumah Sakit",
                        link: "/hospital",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "Subspesialis",
                        link: "/subspesialis",
                      },
                      {
                        icon: PeopleIcon,
                        title: "Ranah Kegiatan",
                        link: "/activity",
                      },
                      {
                        icon: StarsIcon,
                        title: "Cabang",
                        link: "/branch",
                      },
                      // {
                      //   icon: RedeemIcon,
                      //   title: "Benefit",
                      //   link: "/master-benefit",
                      // },
                    ]}
                  />
                  <MenuItemComponent
                    title="Media"
                    icon={DeviceIcon}
                    onClick={() => this.onItemClicked("Media")}
                    active={this.props.selectedItem === "Media"}
                    child={[
                      // {
                      //   icon: IconSchool,
                      //   title: "CMS",
                      //   link: "/dashboard-admin-pusat",
                      // },
                      {
                        icon: MailIcon,
                        title: "Message Blast",
                        link: "/message-blast",
                      },
                      {
                        icon: AdsIcon,
                        title: "Iklan",
                        link: "/iklan",
                      },
                    ]}
                  />
                  {/* <MenuItemComponent
                    title="Pembayaran"
                    icon={PaymentIcon}
                    onClick={() => this.onItemClicked("Pembayaran")}
                    active={this.props.selectedItem === "Pembayaran"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Iuran Paboi",
                        link: "/iuran",
                      },
                      {
                        icon: AccountBalanceIcon,
                        title: "Koperasi",
                        link: "/koperasi",
                      },
                      {
                        icon: RedeemIcon,
                        title: "Benefit",
                        link: "/benefit",
                      },
                      {
                        icon: PeopleIcon,
                        title: "PABOI Cabang",
                        link: "/education",
                      },
                    ]}
                  /> */}
                  <NavLink to="/event" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Event"
                      icon={EventIcon}
                      onClick={() => this.onItemClicked("Event")}
                      active={this.props.selectedItem === "Event"}
                    />
                  </NavLink>
                  {/* <NavLink to="/journal" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Journal"
                      icon={DocumentIcon}
                      onClick={() => this.onItemClicked("Journal")}
                      active={this.props.selectedItem === "Journal"}
                    />
                  </NavLink> */}
                  <NavLink
                    to="/admin/support"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Support"
                      icon={SupportIcon}
                      onClick={() => this.onItemClicked("Support")}
                      active={this.props.selectedItem === "Support"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Report"
                    icon={DocumentIcon}
                    onClick={() => this.onItemClicked("Report")}
                    active={this.props.selectedItem === "Report"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Master",
                        link: "/admin/report-master",
                      },
                      {
                        icon: MonetizationIcon,
                        title: "Iuran Paboi",
                        link: "/admin/iuran",
                      },
                      {
                        icon: AccountBalanceIcon,
                        title: "Koperasi",
                        link: "/admin/iuran-koperasi",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="System"
                    icon={ComputerIcon}
                    onClick={() => this.onItemClicked("System")}
                    active={this.props.selectedItem === "System"}
                    child={[
                      {
                        icon: PeopleIcon,
                        title: "Pengguna",
                        link: "/pengguna",
                      },
                      {
                        icon: DocumentIcon,
                        title: "Audit Trail",
                        link: "/audit-trail",
                      },
                    ]}
                  />
                </>
              )}

              {role === "ketua_kolegium" && (
                <>
                  <NavLink
                    to="/dashboard-admin-kolegium"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Dashboard"
                      icon={IconDashboard}
                      onClick={() =>
                        this.onItemClicked("Dashboard", "dashboard")
                      }
                      active={this.props.selectedItem === "Dashboard"}
                    />
                  </NavLink>
                  <NavLink
                    to="/registrasi"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Registrasi"
                      icon={PeopleAddIcon}
                      onClick={() => this.onItemClicked("Registrasi")}
                      active={this.props.selectedItem === "Registrasi"}
                    />
                  </NavLink>
                  <NavLink to="/ppds" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="PPDS"
                      icon={IconSchool}
                      onClick={() => this.onItemClicked("PPDS")}
                      active={this.props.selectedItem === "PPDS"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Member"
                    icon={IconAccountCircle}
                    onClick={() => this.onItemClicked("Member")}
                    active={this.props.selectedItem === "Member"}
                    child={[
                      {
                        icon: IconSchool,
                        title: "Pendidikan",
                        link: "/admin/member-education",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: AddBoxIcon,
                        title: "Serkom",
                        link: "admin/serkom",
                      },
                    ]}
                  />
                </>
              )}
              {role === "admin_koperasi" && (
                <>
                  <NavLink
                    to="/dashboard-admin-koperasi"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Dashboard"
                      icon={IconDashboard}
                      onClick={() =>
                        this.onItemClicked("Dashboard", "dashboard")
                      }
                      active={this.props.selectedItem === "Dashboard"}
                    />
                  </NavLink>
                  <NavLink
                    to="/koperasi/registrasi"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Manajemen Anggota"
                      icon={PeopleAddIcon}
                      onClick={() => this.onItemClicked("Registrasi")}
                      active={this.props.selectedItem === "Registrasi"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Manajemen Benefit"
                    icon={PaymentIcon}
                    onClick={() => this.onItemClicked("Manajemen Benefit")}
                    active={this.props.selectedItem === "Manajemen Benefit"}
                    child={[
                      {
                        icon: RedeemIcon,
                        title: "Data Master",
                        link: "/master-benefit",
                      },
                      {
                        icon: RedeemIcon,
                        title: "Benefit",
                        link: "/benefit",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="Report"
                    icon={DocumentIcon}
                    onClick={() => this.onItemClicked("Report")}
                    active={this.props.selectedItem === "Report"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Koperasi",
                        link: "/admin/iuran-koperasi",
                      },
                      /* {
                        icon: AccountBalanceIcon,
                        title: "SHU",
                        link: "/admin/shu",
                      }, */
                    ]}
                  />
                </>
              )}
              {role === "bendahara_koperasi" && (
                <>
                  <NavLink to="/benefit" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Benefit"
                      icon={RedeemIcon}
                      onClick={() => this.onItemClicked("Benefit", "Benefit")}
                      active={this.props.selectedItem === "Benefit"}
                    />
                  </NavLink>
                </>
              )}
              {role === "ketua_koperasi" && (
                <>
                  <NavLink to="/benefit" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Benefit"
                      icon={RedeemIcon}
                      onClick={() => this.onItemClicked("Benefit", "Benefit")}
                      active={this.props.selectedItem === "Benefit"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Report"
                    icon={DocumentIcon}
                    onClick={() => this.onItemClicked("Report")}
                    active={this.props.selectedItem === "Report"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Koperasi",
                        link: "/admin/iuran-koperasi",
                      },
                      /* {
                        icon: AccountBalanceIcon,
                        title: "SHU",
                        link: "/admin/shu",
                      }, */
                    ]}
                  />
                </>
              )}

              {(role === "chief_editor" ||
                role === "reviewer" ||
                role === "editor" ||
                role === "language_editor") && (
                <>
                  <NavLink to="/journal" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Journal"
                      icon={DocumentIcon}
                      onClick={() => this.onItemClicked("Journal")}
                      active={this.props.selectedItem === "Journal"}
                    />
                  </NavLink>
                </>
              )}

              {role === "cpd_cme" && (
                <>
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: AddBoxIcon,
                        title: "SKP",
                        link: "/admin-p2kb",
                      },
                    ]}
                  />
                </>
              )}
              {role === "PPDS" && (
                <>
                  <MenuItemComponent
                    title="Manajemen PPDS"
                    icon={IconSchool}
                    onClick={() => this.onItemClicked("Manajemen PPDS")}
                    active={this.props.selectedItem === "Manajemen PPDS"}
                    child={[
                      {
                        icon: IconAccountCircle,
                        title: "Profile",
                        link: "/manajemen-ppds/member-profile",
                      },
                      {
                        icon: IconSchool,
                        title: "Pendidikan",
                        link: "/manajemen-ppds",
                      },
                    ]}
                  />
                </>
              )}
              {role === "ketua_paboi_cabang" && (
                <>
                  <NavLink
                    to="/dashboard-admin-cabang"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Dashboard"
                      icon={IconDashboard}
                      onClick={() =>
                        this.onItemClicked("Dashboard", "dashboard")
                      }
                      active={this.props.selectedItem === "Dashboard"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Member"
                    icon={IconAccountCircle}
                    onClick={() => this.onItemClicked("Member")}
                    active={this.props.selectedItem === "Member"}
                    child={[
                      {
                        icon: PeopleIcon,
                        title: "Profil",
                        link: "/member",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: AddBoxIcon,
                        title: "SKP",
                        link: "/admin-p2kb",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR",
                        link: "/admin/str",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR KT",
                        link: "/admin/strkt",
                      },
                      {
                        icon: PeopleIcon,
                        title: "SIP",
                        link: "/admin/sip",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="Trans/ Mutasi"
                    icon={PeopleAddIcon}
                    onClick={() => this.onItemClicked("Trans/ Mutasi")}
                    active={this.props.selectedItem === "Trans/ Mutasi"}
                    child={[
                      {
                        icon: PeopleAddIcon,
                        title: "Mutasi Anggota",
                        link: "/admin/mutation",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Trans RS",
                        link: "/transrs",
                      },
                    ]}
                  />
                  <NavLink
                    to="/verifikasi-sip"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Verifikasi SIP"
                      icon={IconMail}
                      onClick={() => this.onItemClicked("Verifikasi SIP")}
                      active={this.props.selectedItem === "Verifikasi SIP"}
                    />
                  </NavLink>
                  <NavLink
                    to="/admin/support"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Support"
                      icon={SupportIcon}
                      onClick={() => this.onItemClicked("Support")}
                      active={this.props.selectedItem === "Support"}
                    />
                  </NavLink>

                  <MenuItemComponent
                    title="Report"
                    icon={DocumentIcon}
                    onClick={() => this.onItemClicked("Report")}
                    active={this.props.selectedItem === "Report"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Iuran Paboi",
                        link: "/admin/iuran-cabang",
                      },
                      // {
                      //   icon: AccountBalanceIcon,
                      //   title: "Dana Cabang",
                      //   link: "/admin/transfer-cabang",
                      // },
                      // {
                      //   icon: AccountBalanceIcon,
                      //   title: "Transfer",
                      //   link: "/admin/transfer",
                      // },
                    ]}
                  />
                </>
              )}
              {role === "ketua_paboi_pusat" && (
                <>
                  <NavLink
                    to="/dashboard-admin-pusat"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Dashboard"
                      icon={IconDashboard}
                      onClick={() =>
                        this.onItemClicked("Dashboard", "dashboard")
                      }
                      active={this.props.selectedItem === "Dashboard"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: IconSchool,
                        title: "SKP",
                        link: "/admin-p2kb",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Serkom",
                        link: "/admin/serkom",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR",
                        link: "/admin/str",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR KT",
                        link: "/admin/strkt",
                      },
                      {
                        icon: PeopleIcon,
                        title: "SIP",
                        link: "/admin/sip",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="Trans/ Mutasi"
                    icon={PeopleAddIcon}
                    onClick={() => this.onItemClicked("Trans/ Mutasi")}
                    active={this.props.selectedItem === "Trans/ Mutasi"}
                    child={[
                      {
                        icon: PeopleAddIcon,
                        title: "Mutasi Anggota",
                        link: "/admin/mutation",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Trans RS",
                        link: "/transrs",
                      },
                    ]}
                  />
                  <NavLink to="/event" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Event"
                      icon={EventIcon}
                      onClick={() => this.onItemClicked("Event")}
                      active={this.props.selectedItem === "Event"}
                    />
                  </NavLink>
                  <NavLink
                    to="/admin/support"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Support"
                      icon={SupportIcon}
                      onClick={() => this.onItemClicked("Support")}
                      active={this.props.selectedItem === "Support"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Report"
                    icon={DocumentIcon}
                    onClick={() => this.onItemClicked("Report")}
                    active={this.props.selectedItem === "Report"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Iuran Paboi",
                        link: "/admin/iuran",
                      },
                      {
                        icon: AccountBalanceIcon,
                        title: "Koperasi",
                        link: "/admin/iuran-koperasi",
                      },
                    ]}
                  />
                </>
              )}
              {role === "admin_paboi_cabang" && (
                <>
                  <NavLink
                    to="/dashboard-admin-cabang"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Dashboard"
                      icon={IconDashboard}
                      onClick={() =>
                        this.onItemClicked("Dashboard", "dashboard")
                      }
                      active={this.props.selectedItem === "Dashboard"}
                    />
                  </NavLink>
                  <MenuItemComponent
                    title="Member"
                    icon={IconAccountCircle}
                    onClick={() => this.onItemClicked("Member")}
                    active={this.props.selectedItem === "Member"}
                    child={[
                      {
                        icon: PeopleIcon,
                        title: "Profil",
                        link: "/member",
                      },
                      {
                        icon: IconSchool,
                        title: "Pendidikan",
                        link: "/admin/member-education",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Tempat Praktik",
                        link: "/practice",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "Pelatihan",
                        link: "/admin/member-training",
                      },
                      {
                        icon: PeopleIcon,
                        title: "Pengalaman Kerja",
                        link: "/admin/member-experience",
                      },
                      {
                        icon: StarsIcon,
                        title: "Penghargaan",
                        link: "/admin/member-appreciation",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="P2KB"
                    icon={ChampIcon}
                    onClick={() => this.onItemClicked("P2KB")}
                    active={this.props.selectedItem === "P2KB"}
                    child={[
                      {
                        icon: AddBoxIcon,
                        title: "SKP",
                        link: "/admin-p2kb",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR",
                        link: "/admin/str",
                      },
                      {
                        icon: RecordVoiceOverIcon,
                        title: "STR KT",
                        link: "/admin/strkt",
                      },
                      {
                        icon: PeopleIcon,
                        title: "SIP",
                        link: "/admin/sip",
                      },
                    ]}
                  />
                  <MenuItemComponent
                    title="Trans/ Mutasi"
                    icon={PeopleAddIcon}
                    onClick={() => this.onItemClicked("Trans/ Mutasi")}
                    active={this.props.selectedItem === "Trans/ Mutasi"}
                    child={[
                      {
                        icon: PeopleAddIcon,
                        title: "Mutasi Anggota",
                        link: "/admin/mutation",
                      },
                      {
                        icon: AddBoxIcon,
                        title: "Trans RS",
                        link: "/transrs",
                      },
                    ]}
                  />
                  <NavLink to="/pengurus" exact className={css(styles.navlink)}>
                    <MenuItemComponent
                      title="Pengurus Cabang"
                      icon={PeopleIcon}
                      onClick={() => this.onItemClicked("Pengurus Cabang")}
                      active={this.props.selectedItem === "Pengurus Cabang"}
                    />
                  </NavLink>
                  <NavLink
                    to="/verifikasi-sip"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Verifikasi SIP"
                      icon={IconMail}
                      onClick={() => this.onItemClicked("Verifikasi SIP")}
                      active={this.props.selectedItem === "Verifikasi SIP"}
                    />
                  </NavLink>
                  <NavLink
                    to="/admin/support"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Support"
                      icon={SupportIcon}
                      onClick={() => this.onItemClicked("Support")}
                      active={this.props.selectedItem === "Support"}
                    />
                  </NavLink>

                  <MenuItemComponent
                    title="Report"
                    icon={DocumentIcon}
                    onClick={() => this.onItemClicked("Report")}
                    active={this.props.selectedItem === "Report"}
                    child={[
                      {
                        icon: MonetizationIcon,
                        title: "Iuran Paboi",
                        link: "/admin/iuran-cabang",
                      },
                      // {
                      //   icon: AccountBalanceIcon,
                      //   title: "Dana Cabang",
                      //   link: "/admin/transfer-cabang",
                      // },
                    ]}
                  />
                </>
              )}
              {role === "sekretaris_depoi" && (
                <>
                  <NavLink
                    to="/admin/support"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Support"
                      icon={SupportIcon}
                      onClick={() => this.onItemClicked("Support")}
                      active={this.props.selectedItem === "Support"}
                    />
                  </NavLink>
                </>
              )}
              {role === "ketua_depoi" && (
                <>
                  <NavLink
                    to="/admin/support"
                    exact
                    className={css(styles.navlink)}
                  >
                    <MenuItemComponent
                      title="Support"
                      icon={SupportIcon}
                      onClick={() => this.onItemClicked("Support")}
                      active={this.props.selectedItem === "Support"}
                    />
                  </NavLink>
                </>
              )}

              {/* <NavLink to="/serkom" exact className={css(styles.navlink)}>
                <MenuItemComponent
                  title="Serkom"
                  icon={IconCardMembership}
                  onClick={() => this.onItemClicked("Serkom")}
                  active={this.props.selectedItem === "Serkom"}
                />
              </NavLink>
              <NavLink to="/universitas" exact className={css(styles.navlink)}>
                <MenuItemComponent
                  title="Universitas"
                  icon={IconSchool}
                  onClick={() => this.onItemClicked("Universitas")}
                  active={this.props.selectedItem === "Universitas"}
                />
              </NavLink>
              <NavLink to="/pengguna" exact className={css(styles.navlink)}>
                <MenuItemComponent
                  title="Pengguna"
                  icon={IconAccountCircle}
                  onClick={() => this.onItemClicked("Pengguna")}
                  active={this.props.selectedItem === "Pengguna"}
                />
              </NavLink>
              <NavLink to="/p2kb" exact className={css(styles.navlink)}>
                <MenuItemComponent
                  title="P2KB"
                  icon={IconBook}
                  onClick={() => this.onItemClicked("P2KB")}
                  active={this.props.selectedItem === "P2KB"}
                />
              </NavLink>
              <NavLink to="/Login" exact className={css(styles.navlink)}>
                <MenuItemComponent
                  title="Logout"
                  icon={IconAccountCircle}
                  onClick={() => this.onItemClicked("Logout")}
                  active={this.props.selectedItem === "Logout"}
                />
              </NavLink> */}
            </Column>
          </Column>
          {isMobile && expanded && (
            <div
              className={css(styles.outsideLayer)}
              onClick={this.toggleMenu}
            ></div>
          )}
        </Row>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  burgerIcon: {
    cursor: "pointer",
    position: "absolute",
    left: 24,
    top: 34,
  },
  container: {
    // "transparent linear-gradient(180deg, #2CB96A 0%, #43C767 66%, #63DB62 100%) 0% 0% no-repeat padding-box",
    width: 220,
    paddingTop: 32,
    height: "calc(100% - 32px)",
  },
  containerMobile: {
    transition: "left 0.5s, right 0.5s",
    position: "absolute",
    width: 220,
    height: "100%",
    zIndex: 901,
  },
  mainContainer: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "#31BC69",
  },
  mainContainerMobile: {
    backgroundColor: "transparent",
    position: "absolute",
    minWidth: "100%",
    top: 0,
    left: 0,
  },
  menuItemList: {
    marginTop: 52,
  },
  outsideLayer: {
    position: "absolute",
    width: "100vw",
    minWidth: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.50)",
    zIndex: 900,
  },
  separator: {
    borderTop: "1px solid #DFE0EB",
    marginTop: 16,
    marginBottom: 16,
    opacity: 0.06,
  },
  hide: {
    left: -220,
    backgroundColor: "#31BC69",
  },
  show: {
    left: 0,
    backgroundColor: "#31BC69",
  },
  navlink: {
    textDecoration: "none",
  },
});

export default SidebarComponent;
