import React, { Component } from "react";
import { Row } from "simple-flexbox";
import SidebarComponent from "../sidebar/SidebarComponent";
import HeaderComponent from "../header/HeaderComponent";
import { StyleSheet, css } from "aphrodite";

class Layout extends Component {
  state = { selectedItem: "" };

  render() {
    const { selectedItem } = this.state;
    const { children } = this.props;
    const windowWidth = window.innerWidth;
    return (
      <Row
        horizontal="center"
        style={{
          width: "100%",
          backgroundColor: "#f8f9fb",
        }}
      >
        <div className={css(styles.container)}>
          <SidebarComponent
            selectedItem={selectedItem}
            onChange={(selectedItem) => this.setState({ selectedItem })}
          />
          <div
            className={css(styles.mainBlock)}
            style={{ width: windowWidth > 768 ? "calc(100% - 220px)" : "100%" }}
          >
            <HeaderComponent title={selectedItem} key={1} />
            <div className={css(styles.content)}>{children}</div>
          </div>
        </div>
      </Row>
    );
  }
}

export default Layout;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    maxWidth: 1400,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "12px 0 40px -15px #ddd, -12px 0 30px -15px #0B7535",
  },
  content: {
    marginTop: 100,
    overflow: true,
    width: "100%",
    "@media (max-width: 768px)": {
      marginTop: 20,
    },
  },
  mainBlock: {
    backgroundColor: "#F7F8FC",
    padding: 30,
  },
});
