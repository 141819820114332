import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import moment from "moment/moment";

export const REGISTERED_LIST_PENDING = "REGISTERED_LIST_PENDING";
export const REGISTERED_LIST_SUCCESS = "REGISTERED_LIST_PENDING_SUCCESS";
export const REGISTERED_LIST_ERROR = "REGISTERED_LIST_PENDING_ERROR";
export const DETAIL_REGISTERED_MEMBER_SUCCESS = "DETAIL_REGISTERED_MEMBER_SUCCESS";
export const DETAIL_REGISTERED_MEMBER_ERROR = "DETAIL_REGISTERED_MEMBER_ERROR";
export const DETAIL_REGISTERED_MEMBER_PENDING = "DETAIL_REGISTERED_MEMBER_PENDING";
export const APPROVAL_REGISTERED_MEMBER_SUCCESS = "APPROVAL_REGISTERED_MEMBER_SUCCESS";
export const APPROVAL_REGISTERED_MEMBER_ERROR = "APPROVAL_REGISTERED_MEMBER_ERROR";
export const APPROVAL_REGISTERED_MEMBER_PENDING = "APPROVAL_REGISTERED_MEMBER_PENDING";


const REGISTERED_LIST_URL = "member/member/find_register_mobile";
const APPROVAL_URL = "member/member/approval_register_mobile";

export function registeredList(param = {}, resolve, reject, callback, checklist) {
    return (dispatch) => {
        dispatch(actionPending(REGISTERED_LIST_PENDING));
        API.post(REGISTERED_LIST_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(REGISTERED_LIST_SUCCESS, res.data));
                const { records_total } = res.data;
                let data = res.data.data.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * param.length,
                    ...item,
                    province: item?.province_data.name,
                    registrationDate: moment(item.created_at).format('DD MMMM YYYY'),
                    education: item?.is_subspecialist ? "Subspesialis" : "Spesialis",
                    records_total: res.data.records_total,
                }));
                if (resolve) {
                    resolve({
                        data: data,
                        page: param.page - 1,
                        totalCount: records_total,
                    });
                }
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch(actionError(REGISTERED_LIST_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    };
}

export function registeredMemberApproval(param, callback, type) {
    return (dispatch) => {
        dispatch(actionPending(APPROVAL_REGISTERED_MEMBER_PENDING));
        API.post(APPROVAL_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(APPROVAL_REGISTERED_MEMBER_SUCCESS, res.data.data));
                if(type === "accepted"){
                    toastSuccess("Berhasil menerima registrasi anggota.");
                }else{
                    toastSuccess("Berhasil menolak registrasi anggota.");
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(APPROVAL_REGISTERED_MEMBER_ERROR, error));
                toastError(error, "Gagal Approval, ");
            });
    };
}
