import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Column, Row} from "simple-flexbox";
import {css, StyleSheet} from "aphrodite";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailMemberPhoto from "../../components/detail/DetailMemberPhoto";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailProfile from "./Detail_Profile";
import DetailPendidikan from "./Detail_Pendidikan";
import DetailBerkas from "./Detail_Berkas";
import {
    getRegisteredList,
    getRegisteredListError,
    getRegisteredListPending
} from "../../reducers/registrasi_member/regist_member_reducer";
import {registeredList} from "../../actions/registrasi_member/regist_member_action";
import DetailPenolakan from "./Detail_Penolakan";
import Button from "@material-ui/core/Button";
import ModalApproval from "./Modal_Approval";
import ModalRejected from "./Modal_Rejected";

class DetailRegistrasiMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalApproveShow: false,
            modalRejectShow: false,
            selectedItem: "profil",
            data: null
        };
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        if(!this.state.data){
            this.props.registeredList({
                id: id
            })
        }
    }

    setSelectedItem(item) {
        this.setState({ selectedItem: item });
    }

    render() {
        const {registeredList_data} = this.props;
        const { modalApproveShow, modalRejectShow } = this.state;

        let data = this.state.data ?? registeredList_data?.data[0];

        let detail_status = data?.status;
        return (
            <ContentContainer>
                <ModalApproval
                    name={`${data?.other_front_title} ${data?.name}, ${data?.other_back_title}`}
                    show={modalApproveShow}
                    onHide={() => this.setState({ modalApproveShow: false })}
                />
                <ModalRejected
                    name={`${data?.other_front_title} ${data?.name}, ${data?.other_back_title}`}
                    show={modalRejectShow}
                    onHide={() => this.setState({ modalRejectShow: false })}
                />
                <Row>
                    <Row className={css(styles.leftDetailContainer)}>
                        <Column className={css(styles.leftDetailColumn)}>
                            <DetailMemberPhoto filename={data?.photo} source="api" folder="member_supporting_files"/>
                            <DetailNameComponent name={`${data?.other_front_title} ${data?.name}, ${data?.other_back_title}`}/>
                            <TableStatusComponent style={{marginTop: 20}}
                                status={detail_status === 2 ? "Ditolak" : detail_status === 1 ? "Diterima" : "Baru"}
                            />
                            <DetailMenuComponent
                                onClick={() => this.setSelectedItem("profil")}
                                isActive={this.state.selectedItem === "profil"}
                                menuName="Profil" i
                                />
                            <DetailMenuComponent
                                onClick={() => this.setSelectedItem("pendidikan")}
                                isActive={this.state.selectedItem === "pendidikan"}
                                menuName="Pendidikan"
                            />
                            <DetailMenuComponent
                                onClick={() => this.setSelectedItem("berkas")}
                                isActive={this.state.selectedItem === "berkas"}
                                menuName="Berkas Pendukung"
                            />
                            { detail_status === 2 && <DetailMenuComponent
                                onClick={() => this.setSelectedItem("rejected")}
                                isActive={this.state.selectedItem === "rejected"}
                                menuName="Alasan Penolakan"
                            />}
                            { detail_status === 0 &&
                                <Row style={{width: "100%", marginTop: "5rem"}} horizontal="space-between">
                                <Button
                                    variant="contained"
                                    className={css(styles.rejectButton)}
                                    onClick={() =>
                                        this.setState({modalRejectShow: true})
                                    }
                                >
                                    Tolak
                                </Button>
                                <Button
                                    variant="contained"
                                    className={css(styles.approveButton)}
                                    onClick={() =>
                                        this.setState({modalApproveShow: true})
                                    }
                                >
                                    Terima
                                </Button>
                            </Row>}
                        </Column>
                    </Row>
                    <RightContainerComponent>
                        {this.state.selectedItem === "profil" && <DetailProfile/>}
                        {this.state.selectedItem === "pendidikan" && <DetailPendidikan/>}
                        {this.state.selectedItem === "berkas" && <DetailBerkas/>}
                        {this.state.selectedItem === "rejected" && <DetailPenolakan/>}
                    </RightContainerComponent>
                </Row>
            </ContentContainer>
        );
    }
}

const styles = StyleSheet.create({
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40,
        alignItems: "center",
        justifyContent: "center",
        display: "block"
    },
    rightDetailContainer: {
        padding: 40,
        width: "70%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    leftDetailColumn: {
        justifyContent: "center",
        alignItems: "center"
    },
    approveButton: {
        background: "#00B559 0% 0% no-repeat padding-box",
        boxShadow: "none",
        color: "white",
        width: "45%",
    },
    rejectButton: {
        background: "#F26664 0% 0% no-repeat padding-box",
        boxShadow: "none",
        color: "white",
        width: "45%",
    },
    detailMemberStatusNonactiveID: {
        padding: "10px 20px",
        border: "2px solid #ff3232",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
    },
    detailMemberStatusActiveID: {
        padding: "10px 20px",
        border: "2px solid #2CB96A",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
    },
    detailMemberStatusPensionID: {
        padding: "10px 20px",
        border: "2px solid #ff8c32",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
    },
    detailMemberStatusDepartedID: {
        padding: "10px 20px",
        border: "2px solid #727272",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
    },
    detailMemberStatusNonactiveButton: {
        padding: "10px 20px",
        backgroundColor: "#ff3232",
        border: "2px solid #ff3232",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        height: "100%",
        alignItems: "center",
        ":hover": {
            cursor: "pointer"
        }
    },
    detailMemberStatusActiveButton: {
        padding: "10px 20px",
        backgroundColor: "#2CB96A",
        border: "2px solid #2CB96A",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        height: "100%",
        alignItems: "center",
        ":hover": {
            cursor: "pointer"
        }
    },
    detailMemberStatusPensionButton: {
        padding: "10px 20px",
        backgroundColor: "#ff8c32",
        border: "2px solid #ff8c32",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        height: "100%",
        alignItems: "center",
        ":hover": {
            cursor: "pointer"
        }
    },
    detailMemberStatusDepartedButton: {
        padding: "10px 20px",
        backgroundColor: "#727272",
        border: "2px solid #727272",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        height: "100%",
        alignItems: "center",
        ":hover": {
            cursor: "pointer"
        }
    },
    detailMemberStatusNonactiveIDFont: {
        color: "#ff3232"
    },
    detailMemberStatusActiveIDFont: {
        color: "#2CB96A"
    },
    detailMemberStatusPensionIDFont: {
        color: "#ff8c32"
    },
    detailMemberStatusDepartedIDFont: {
        color: "#727272"
    },
    detailMemberStatusButtonFont: {
        color: "#FFFFFF",
        marginRight: 20
    },
    changeStatusButton: {
        padding: 0,
        fontSize: 14
    },
    clickableIcon: {
        ":hover": {
            cursor: "pointer"
        }
    }
});

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
        registeredList_data: getRegisteredList(registeredList),
        registeredList_error: getRegisteredListError(registeredList),
    };
}

export default connect(mapStateToProps, { registeredList })(
    withRouter(DetailRegistrasiMember)
);