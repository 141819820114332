import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { registeredList } from "../../actions/registrasi_member/regist_member_action";
import {
    getRegisteredListPending,
    getRegisteredList,
    getRegisteredListError,
} from "../../reducers/registrasi_member/regist_member_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import HeaderRegistrasiMember from "./Header_registrasi";
import ModalFilter from "./Modal_filter";

class ListRegistrasiMember extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            showFilter: false,
            search: "",
            province_id: "",
            education_type: "",
            start_date: "",
            end_date: "",
            id: null,
            columns: [
                {
                    title: "No",
                    render: (rowData) => {
                        return <>{rowData.no}</>;
                    },
                    width: 20,
                    headerStyle: {
                        paddingLeft: 40,
                    },
                    cellStyle: {
                        paddingLeft: 40,
                    },
                },
                { title: "Tanggal Registrasi", field: "registrationDate" },
                { title: "Nama Lengkap",  field: "name" },
                { title: "Provinsi", field: "province" },
                {
                    title: "Pendidikan",
                    field: "education"
                },
                {
                    title: "Status",
                    field: "status",
                    render: ({ status }) => {
                        return (
                            <TableStatusComponent
                                status={
                                    status === 2
                                        ? "Ditolak"
                                        : status === 1
                                            ? "Diterima"
                                            : "Baru"
                                }
                            />
                        );
                    },
                },
                {
                    title: "",
                    render: ({ id }) =>
                        <DetailButtonComponent>
                            <MenuItem onClick={() => this.detailRecord(id)}>
                                Detail
                            </MenuItem>
                        </DetailButtonComponent>
                },
            ],
        };
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleOpenFilterModal = this.handleOpenFilterModal.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.setState({ id: null });
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleOpenFilterModal() {
        this.setState({
            showFilter: true,
        });
    }

    detailRecord(id) {
        this.props.history.push(`/registrasi-member/detail/${id}`);
    }

    componentDidMount() {
        const url = window.location.href;
        const ID = url.substring(url.lastIndexOf("/") + 1);
        const isID = url.substring(url.lastIndexOf("/") + 1) !== "registrasi";
        if (isID && this.state.id === null) {
            this.setState({ id: ID });
        }
    }


    // tableRef = React.createRef();
    render() {
        const { showFilter } = this.state;
        const { registeredList, registeredList_data } = this.props;
        let data = [];
        let records_total = "";
        let length = "";
        if (registeredList_data) {
            data = registeredList_data;
            records_total = data.records_total;
            length = data.data.length;
        }
        return (
            <ContentContainer>
                <ModalFilter
                    show={showFilter}
                    onHide={() => this.setState({ showFilter: false })}
                    handleRefresh={this.handleRefresh}
                />
                <HeaderRegistrasiMember handleRefresh={this.handleRefresh} onShowModal={this.handleOpenFilterModal} />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Registrasi"
                    columns={this.state.columns}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                status: this.state.status,
                                province_id: this.state.province_id,
                                education_type: this.state.education_type,
                                start_date: this.state.start_date,
                                end_date: this.state.end_date,
                                id: "",
                            };
                            registeredList(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            color: "#9DA6BA",
                            backgroundColor: "#F8F9FB99",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <Row
                                horizontal="space-between"
                                style={{ paddingRight: 20, paddingLeft: 20 }}
                            >
                                <Row
                                    style={{
                                        width: "50%",
                                        borderBottom: "1px solid rgba(224, 224, 224)",
                                    }}
                                    vertical="center"
                                >
                                    <p>
                                        {" "}
                                        Menampilkan {length} dari {records_total} data{" "}
                                    </p>
                                </Row>
                                <TablePagination
                                    {...props}
                                    color="primary"
                                    labelRowsPerPage={
                                        <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                    }
                                    labelDisplayedRows={(row) => (
                                        <div style={{ fontSize: 14, color: "red" }}>
                                            {/* {props.labelDisplayedRows(row)} */}
                                        </div>
                                    )}
                                    SelectProps={{
                                        style: {
                                            fontSize: 20,
                                        },
                                    }}
                                />
                            </Row>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
        registeredList_data: getRegisteredList(registeredList),
        registeredList_error: getRegisteredListError(registeredList),
    };
}

export default connect(mapStateToProps, { registeredList })(
    withRouter(ListRegistrasiMember)
);
