import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Column, Row} from "simple-flexbox";
import {css, StyleSheet} from "aphrodite";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import {
    getRegisteredList,
    getRegisteredListError,
    getRegisteredListPending
} from "../../reducers/registrasi_member/regist_member_reducer";
import {registeredList} from "../../actions/registrasi_member/regist_member_action";
import DetailContentComponent from "../../components/detail/DetailContentComponent";

class DetailPenolakan extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedItem: "profil", data: null };
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        this.props.registeredList({
            id: id
        })
    }

    render() {
        const {registeredList_data} = this.props;
        const data = registeredList_data?.data[0];
        return (
            <Row className={css(styles.rightDetailContainer)}>
                <Column className={css(styles.rightDetailColumn)}>
                    <DetailTitleComponent title="Alasan Penolakan"/>
                    <DetailContentComponent label="Alasan" value={data?.reject_notes}/>
                </Column>
            </Row>
        );
    }
}

const styles = StyleSheet.create({
    rightDetailContainer: {
        padding: 0,
        width: "100%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40,
        alignItems: "center",
        justifyContent: "center",
        display: "block"
    },
    leftDetailColumn: {
        justifyContent: "center",
        alignItems: "center"
    },
    changeStatusButton: {
        padding: 0,
        fontSize: 14
    },
    clickableIcon: {
        ":hover": {
            cursor: "pointer"
        }
    }
});

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
        registeredList_data: getRegisteredList(registeredList),
        registeredList_error: getRegisteredListError(registeredList),
    };
}

export default connect(mapStateToProps, { registeredList })(
    withRouter(DetailPenolakan)
);