import React from "react";
import { StyleSheet } from "aphrodite";

import { bool } from "prop-types";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import API from "../../actions/API";
import getHeader from "../../actions/header";

const styles = StyleSheet.create({
  container: {
    zIndex: 30,
  },
});

const dot = (color) => ({
  alignItems: "center",
  display: "flex",

  ":before": color ? {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  } : undefined,
});


const colourStyles = {
  control: (style, state) => {
    return {
      ...style,
      backgroundColor: state.hasValue ? "#2CB96A1A" : "#EFF0F2",
      color: state.hasValue ? "#2CB96A1A" : styles.color,
      // match with the menu

      borderRadius: state.selectProps.isRounded ? 20 : 4,
      // Overwrittes the different states  of border
      borderColor: state.isFocused
        ? "#2CB96A"
        : state.hasValue
          ? "#2CB96A1A"
          : "#EFF0F2",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#2CB96A",
      },
      fontFamily: "Circular Std Book",
      fontSize: 14,
    };
  },
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 12,
    // kill the gap
    marginTop: 0,
    fontFamily: "Circular Std Book",
    fontSize: 14,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  input: (styles) => {
    return {
      ...styles,
      fontFamily: "Circular Std Book",
      "& input": {
        font: "inherit",
      },
    };
  },
  placeholder: (styles) => ({
    ...styles,
    fontFamily: "Circular Std Book",
    fontSize: 14,
    color: "#495057",
    opacity: 0.68,
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#2CB96A",
    fontFamily: "Circular Std Book",
    fontSize: 14,
    ...dot(data.color),
  }),
};


const SelectComponent = (props) => {
  const delayedQuery = _.debounce((inputValue, callback) => {
    API.post(props.asyncUrl, { search_text: inputValue }, getHeader())
      .then(res => {
        callback(
          res.data.data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        )
      })
  }, 500)

  const loadOptions = (inputValue, callback) => {
    delayedQuery(inputValue, callback)
  };

  return (
    props.isAsync ?
      <AsyncSelect
        isClearable={props.isClearable}
        styles={{ ...colourStyles }}
        loadOptions={loadOptions}
        defaultOptions={props.options}
        {...props}
      />
      :
      <Select
        isClearable={props.isClearable}
        styles={{ ...colourStyles }}
        {...props}
      />
  )
}

SelectComponent.propsTypes = {
  isClearable: bool,
};
SelectComponent.defaultProps = {
  isClearable: true,
};

export default SelectComponent;
