import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Column, Row} from "simple-flexbox";
import {css, StyleSheet} from "aphrodite";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import {
    getRegisteredList,
    getRegisteredListError,
    getRegisteredListPending
} from "../../reducers/registrasi_member/regist_member_reducer";
import {registeredList} from "../../actions/registrasi_member/regist_member_action";
import ModalDetailDokumen from "../admin-p2kb/Modal_document";

class DetailBerkas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: "profil",
            data: null,
            modalDetailDokumenShow: false,
            imgUrl: "",
            modal_title: "",
            folder: "member_supporting_files",
        };
        this.tableRef = React.createRef();
        this.handleOpenFoto = this.handleOpenFoto.bind(this);
        this.handleOpenKTP = this.handleOpenKTP.bind(this);
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        this.props.registeredList({
            id: id
        })
    }

    handleOpenFoto(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl });
        this.setState({ modal_title: modal_title });
        this.setState({ modalDetailDokumenShow: true });
    }

    handleOpenKTP(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl });
        this.setState({ modal_title: modal_title });
        this.setState({ modalDetailDokumenShow: true });
    }

    render() {
        const {registeredList_data} = this.props;
        const data = registeredList_data?.data[0];
        return (
            <Row className={css(styles.rightDetailContainer)}>
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => {
                        this.setState({ modalDetailDokumenShow: false })
                        setTimeout(function() {
                            document.body.removeAttribute('style')
                        }, 500);
                    }}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                    folder={this.state.folder}
                />
                <Column className={css(styles.rightDetailColumn)}>
                    <DetailTitleComponent title="Berkas Pendukung"/>
                    {(!data?.photo && !data?.scan_ktp_image) ? <div className={css(styles.span)}>Tidak ada data Berkas Pendukung</div> :
                        <div>
                            <div className={css(styles.span)}>
                                Foto
                            </div>
                            <DetailContentLihatBerkas onClick={() =>
                                this.handleOpenFoto(
                                    data?.photo,
                                    "Foto"
                                )
                            }/>
                            <div className={css(styles.span)}>
                                KTP
                            </div>
                            <DetailContentLihatBerkas onClick={() =>
                                this.handleOpenKTP(
                                    data?.scan_ktp_image,
                                    "KTP"
                                )
                            }/>
                        </div>
                    }
                    </Column>
                </Row>
        );
    }
}

const styles = StyleSheet.create({
    span: {
        marginBottom: 5,
        marginTop: 10,
        color: "#495057"
    },
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40,
        alignItems: "center",
        justifyContent: "center",
        display: "block"
    },
    rightDetailContainer: {
        padding: 0,
        width: "100%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    leftDetailColumn: {
        justifyContent: "center",
        alignItems: "center"
    },
    changeStatusButton: {
        padding: 0,
        fontSize: 14
    },
    clickableIcon: {
        ":hover": {
            cursor: "pointer"
        }
    }
});

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
        registeredList_data: getRegisteredList(registeredList),
        registeredList_error: getRegisteredListError(registeredList),
    };
}

export default connect(mapStateToProps, { registeredList })(
    withRouter(DetailBerkas)
);