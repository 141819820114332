import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import {Column, Row} from "simple-flexbox";
import {css, StyleSheet} from "aphrodite";
import SelectComponent from "../../components/form/SelectComponent";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import Button from "@material-ui/core/Button";
import {DateRangePicker} from "react-dates";
import "./customDateRangePicker.css"
import {
    getProvinceList,
    getProvinceListError,
    getProvinceListPending
} from "../../reducers/master-data/province_reducer";
import {connect} from "react-redux";
import {provinceList} from "../../actions/master-data/province_action";
import {withRouter} from "react-router-dom";
import moment from "moment";

class ModalFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: {
                label: "SEMUA PROVINSI",
                value: "",
            },
            education: {
                label: "Semua Pendidikan",
                value: "",
            },
            status: {
                label: "Semua Status",
                value: "",
            },
            startDate: null,
            endDate: null,
        }
        this.handleChangeProvince = this.handleChangeProvince.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEducation = this.handleChangeEducation.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleChangeStatus(e) {
        if (e) {
            this.setState({
                status: {
                    label: e.label,
                    value: e.value,
                }
            });
        } else {
            this.setState({ status: "" });
        }
    }

    handleChangeProvince(e) {
        if (e) {
            this.setState({
                province: {
                    label: e.label,
                    value: e.value,
                }
            });
        } else {
            this.setState({ province: "" });
        }
    }

    handleChangeEducation(e) {
        if (e) {
            this.setState({
                education: {
                    label: e.label,
                    value: e.value,
                }
            });
        } else {
            this.setState({ education: "" });
        }
    }

    componentDidMount() {
        let param_provinceList = {
            length: 999,
        };
        this.props.provinceList(param_provinceList);
    }

    handleSubmit(){
        const { onHide, handleRefresh} = this.props;
        const { province, education, startDate, endDate, status} = this.state;
        const param = {
            status: status.value,
            province_id: province.value,
            education_type: education.value,
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
            end_date: startDate ? moment(endDate).format("YYYY-MM-DD") : "",
        }
        handleRefresh(param);
        onHide()
    };

    handleReset(){
        this.setState({
            province: {
                label: "SEMUA PROVINSI",
                value: "",
            },
            education: {
                label: "Semua Pendidikan",
                value: "",
            },
            status: {
                label: "Semua Status",
                value: "",
            },
            startDate: null,
            endDate: null,
        })
    }

    render() {
        const { show, onHide } = this.props;
        const { province, education, status } = this.state;
        let provinceOption = [];
        const EducationList = [
            {label: "Semua Pendidikan", value: "", },
            {label: "Spesialis", value: "specialist"},
            {label: "Subspesialis", value: "subspecialist"},
        ]
        const StatusList = [
            {label: "Semua Status", value: "", },
            {label: "Baru", value: "0"},
            {label: "Diterima", value: "1"},
            {label: "Ditolak", value: "2"},
        ]
        const { provinceList_data } = this.props;
        if (provinceList_data) {
            provinceOption = provinceList_data.map((value, index) => {
                return {value: value.province_id, label: value.name};
            });
            provinceOption = [
                {label: "SEMUA PROVINSI", value: "", },
                ...provinceOption,
            ]
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
                style={{borderRadius: 20}}
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Filter</p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <LabelInputVerticalComponent label="Provinsi" marginTop="20px">
                            <SelectComponent
                                isClearable={false}
                                placeholder="Masukkan provinsi"
                                options={provinceOption}
                                onChange={this.handleChangeProvince}
                                value={province}
                                id="province"
                            />
                        </LabelInputVerticalComponent>
                        <LabelInputVerticalComponent label="Pendidikan" marginTop="20px">
                            <SelectComponent
                                isClearable={false}
                                placeholder="Masukkan pendidikan"
                                options={EducationList}
                                onChange={this.handleChangeEducation}
                                value={education}
                                id="education"
                            />
                        </LabelInputVerticalComponent>
                        <LabelInputVerticalComponent label="Status" marginTop="20px">
                            <SelectComponent
                                isClearable={false}
                                placeholder="Masukkan status"
                                options={StatusList}
                                onChange={this.handleChangeStatus}
                                value={status}
                                id="status"
                            />
                        </LabelInputVerticalComponent>
                        <LabelInputVerticalComponent label="Date Range" marginTop="20px">
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="reg_id"
                                endDate={this.state.endDate}
                                endDateId="reg_id"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                isOutsideRange={() => false}
                                showDefaultInputIcon
                                inputIconPosition="before"
                                showClearDates
                                small
                                block
                                numberOfMonths={1} autoFocus
                            />
                        </LabelInputVerticalComponent>

                        <Row
                            horizontal="space-between"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.rejectButton)}
                                onClick={this.handleReset}
                            >
                                RESET
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={css(styles.approveButton)}
                                onClick={this.handleSubmit}
                            >
                                APPLY
                            </Button>
                        </Row>
                    </Column>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    labelMargin: {
        marginTop: 10,
        marginBottom: 10,
    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        width: "100%",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#00B559",
        backgroundColor: "#FFFFF",
        borderColor: "#00B559",
        boxShadow: "none",
        width: "100%",
    },
    footerModal: {
        marginTop: 20,
        height: 80,
        width: "100%",
    },
});


function mapStateToProps({  provinceList }) {
    return {
        provinceList_pending: getProvinceListPending(provinceList),
        provinceList_data: getProvinceList(provinceList),
        provinceList_error: getProvinceListError(provinceList),
    };
}

export default connect(mapStateToProps, {
    provinceList,
})(withRouter(ModalFilter));
