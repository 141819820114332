import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import {
  setSelectedHeader,
  setSelectedLinkHeader,
} from "./header/header_reducer";
import { authentication } from "./login/login_reducer";
import {
  registrationList,
  registrationVerification,
  ppdsStatusApproval,
} from "./registrasi/registrasi_reducer";
import {
  memberList,
  memberListId,
  changeMemberStatus,
  detailMember,
  memberP2kbExisting,
  editMember,
  selectedMember,
  uploadBerkas,
  memberChangePassword,
} from "./member/member_reducer";
import {
  listPpds,
  detailPpds,
  chanchangeStatusGraduation,
  editPpds,
} from "./member/ppds_reducer";
import {
  listHospital,
  addHospital,
  deleteHospital,
  editHospital,
  detailHospital,
} from "./master-data/hospital_reducer";
import {
  listMembership,
  addMembership,
  deleteMembership,
  editMembership,
  detailMembership,
  selectedMembership,
} from "./koperasi/membership_reducer";
import {
  universityList,
  editUniversity,
  addUniversity,
  deleteUniversity,
} from "./master-data/university_reducer";
import { interestList } from "./master-data/interest_reducer";
import { listCity } from "./master-data/city_reducer";
import {
  branchList,
  listBranch,
  listMemberDistribution,
  detailBranch,
  editBranch,
  deleteBranch,
  addBranch,
} from "./master-data/branch_reducer";
import {
  listEducation,
  detailEducation,
  editEducation,
  uploadEducation,
  addEducation,
  approveEducation,
} from "./member/education_reducer";
import {
  listAppreciation,
  detailAppreciation,
  editAppreciation,
  deleteAppreciation,
  addAppreciation,
} from "./member/appreciation_reducer";
import {
  listTraining as listTrainingMember,
  detailTraining as detailTrainingMember,
  editTraining as editTrainingMember,
  deleteTraining as deleteTrainingMember,
  addTraining as addTrainingMember,
  approveTraining as approveTrainingMember,
} from "./member/training_reducer";
import {
  listExperience as listExperienceMember,
  detailExperience as detailExperienceMember,
  editExperience as editExperienceMember,
  addExperience as addExperienceMember,
  approveExperience as approveExperienceMember,
} from "./member/experience_reducer";
import {
  listSerkom,
  detailSerkom,
  editSerkom,
  deleteSerkom,
  addSerkom,
  approveSerkom,
  memberSerkom,
} from "./serkom/serkom_reducer";
import {
  listMutation,
  detailMutation,
  editMutation,
  addMutation,
  approveMutation,
  uploadMutation,
} from "./mutation/mutation_reducer";
import {
  listPPDS,
  listPPDSAdmin,
  detailPPDS,
  editPPDS,
  addPPDS,
  approvePPDS,
  uploadPPDS,
} from "./manajemen-ppds/manajemen_ppds_reducer";
import {
  listSupport,
  detailSupport,
  editSupport,
  addSupport,
  approveSupport,
} from "./support/support_reducer";
import {
  listReport,
  listTransfer,
  importReport,
  masterReport,
} from "./report/report_reducer";
import {
  listSerkomAdmin,
  detailSerkomAdmin,
  editSerkomAdmin,
  deleteSerkomAdmin,
  addSerkomAdmin,
  // approveSerkomAdmin,
} from "./serkom/serkomAdmin_reducer";
import { listActivity, detailActivity } from "./master-data/activity_reducer";
import { listProvince } from "./master-data/province_temp_reducer";
import { provinceList } from "./master-data/province_reducer";
import { roleList } from "./master-data/role_reducer";
import {
  userList,
  editUser,
  addUser,
  deleteUser,
  changeImageProfile,
} from "./master-data/user_reducer";
import {
  trainingList,
  editTraining,
  addTraining,
  deleteTraining,
} from "./master-data/training_reducer";
import {
  experienceList,
  editExperience,
  addExperience,
  deleteExperience,
} from "./master-data/experience_reducer";
import {
  listPractice,
  editPractice,
  addPractice,
  detailPractice,
  changeStatusPractice,
} from "./sub-menu/practice_reducer";
import { memberRegister } from "./guests/member_register_reducer";
import { memberVerifyPassword } from "./guests/member_verify_password_reducer";
import { memberForgotPassword } from "./guests/member_forgot_password_reducer";
import { userChangePassword } from "./master-data/user_change_password_reducer";
import {
  p2kbList,
  addP2kb,
  editP2kb,
  detailP2kb,
  dateP2kb,
  deleteP2kb,
  uploadP2kb,
  approvalP2kb,
  approvalAllP2kb,
  submitAllP2kb,
  editDetailP2kb,
  rejectDetailP2kb,
  selectedSKP,
  p2kbListMember,
} from "./p2kb/p2kb_reducer";
import { realmActivityList } from "./master-data/realm_activity_reducer";
import {
  listSubspesialis,
  addSubspesialis,
  deleteSubspesialis,
  editSubspesialis,
  detailSubspesialis,
} from "./master-data/subspesialis_reducer";
import {
  listStr,
  addStr,
  deleteStr,
  editStr,
  detailStr,
  approveStr,
} from "./p2kb/str_reducer";
import {
  listStrKT,
  addStrKT,
  editStrKT,
  detailStrKT,
  approveStrKT,
} from "./p2kb-kt/str_kt_reducer";
import {
  listSip,
  addSip,
  deleteSip,
  editSip,
  detailSip,
  approveSip,
  changeSipType,
} from "./p2kb/sip_reducer";
import {
  listEvent,
  approveEvent,
  detailEvent,
  addEvent,
  deleteEvent,
  editEvent,
  selectedEvent,
} from "./event/event_reducer";
import {
  listJournal,
  approvalJournal,
  rejectApprovalJournal,
  detailJournal,
  editJournal,
  selectedJournal,
  publishJournal,
  revisionJournal,
} from "./journal/journal_reducer";
import {
  listIuran,
  approvalIuran,
  detailIuran,
  addIuran,
  selectedIuran,
} from "./iuran/iuran_reducer";
import {
  listKoperasi,
  approvalKoperasi,
  detailKoperasi,
  addKoperasi,
  selectedKoperasi,
  findSHUKoperasi,
  updateSHUKoperasi,
  mainDeposit,
} from "./koperasi/koperasi_reducer";
import {
  listPPDSGrafik,
  listPPDSAcceptance,
  listPPDSGO,
} from "./ppds/ppds_reducer";
import {
  listBenefit,
  approvalBenefit,
  detailBenefit,
  addBenefit,
  selectedBenefit,
  editBenefit,
  uploadBenefit,
  // deleteBenefit,
} from "./benefit/benefit_reducer";
import {
  listMasterBenefit,
  editMasterBenefit,
  detailMasterBenefit,
  addMasterBenefit,
  selectedMasterBenefit,
  deleteMasterBenefit,
} from "./master-data/master_benefit_reducer";
import {
  transrsList,
  addTransrs,
  editTransrs,
  selectedTransrs,
  detailTransrs,
  approvalTransrs,
  uploadTransrs,
} from "./transrs/transrs_reducer";
import {
  SIPList,
  addSIP,
  editSIP,
  selectedSIP,
  detailSIP,
  approvalSIP,
  uploadSIP,
} from "./pengajuan-sip/sip_reducer";
import {
  messageBlastList,
  addMessageBlast,
  selectedMessageBlast,
  detailMessageBlast,
  uploadMessageBlast,
} from "./message-blast/message_blast_reducer";
import {
  branchmanList,
  addBranchman,
} from "./branch-management/branchman_reducer";
import {
  listCandidate,
  listGraduated,
  listOrthopaedi,
  listSubspecialist,
} from "./dashboard/kolegium_reducer";
import {
  listTotalMember,
  listTotalMemberDetail,
  listTotalFeeIn,
  listEstimationFee,
  listAllocation,
  listSubspecialist as listSubspecialist_cabang,
} from "./dashboard/cabang_reducer";
import {
  listTotalMember as listTotalMember_pusat,
  listTotalMemberCabang as listTotalMember_pusat_cabang,
  listTotalFeeIn as listTotalFeeIn_pusat,
  listEstimationFee as listEstimationFee_pusat,
  listAllocation as listAllocation_pusat,
  listSubspecialist as listSubspecialist_pusat,
  listCooperative as listCooperative_pusat,
} from "./dashboard/pusat_reducer";
import {
  listCooperative,
  listMainDeposit,
  listRequiredDeposit,
  listSHU,
  reportSHU,
} from "./dashboard/koperasi_reducer";
import {
  listAdvertisement,
  listActiveAdvertisement,
  detailAdvertisement,
  addAdvertisement,
  selectedAdvertisement,
  editAdvertisement,
  deleteAdvertisement,
} from "./advertisement/advertisement_reducer";
import { listAuditTrail } from "./audit-trail/audit_trail_reducer";
import {
  listFinalPaper,
  detailFinalPaper,
  addFinalPaper,
  selectedFinalPaper,
  editFinalPaper,
  deleteFinalPaper,
} from "./cms-final-paper/cms_final_paper_reducer";
import {
  notificationList,
  readNotification,
} from "./notification/notification_reducer";
import {
  registeredList,
  detailRegisteredMember,
} from "./registrasi_member/regist_member_reducer";

const rootReducer = combineReducers({
  form: formReducer,
  authentication: authentication,
  registrationList: registrationList,
  ppdsStatusApproval: ppdsStatusApproval,
  memberList: memberList,
  memberListId: memberListId,
  detailMember: detailMember,
  memberP2kbExisting: memberP2kbExisting,
  editMember: editMember,
  selectedMember: selectedMember,
  branchList: branchList,
  registrationVerification: registrationVerification,
  listPpds: listPpds,
  detailPpds: detailPpds,
  universityList: universityList,
  interestList: interestList,
  editUniversity: editUniversity,
  addUniversity: addUniversity,
  deleteUniversity: deleteUniversity,
  chanchangeStatusGraduation: chanchangeStatusGraduation,
  provinceList: provinceList,
  editPpds: editPpds,
  listCity: listCity,
  listProvincetTemp: listProvince,
  listActivity: listActivity,
  detailActivity: detailActivity,
  detailBranch: detailBranch,
  listBranch: listBranch,
  listMemberDistribution: listMemberDistribution,
  editBranch: editBranch,
  deleteBranch: deleteBranch,
  addBranch: addBranch,
  listEducation: listEducation,
  detailEducation: detailEducation,
  editEducation: editEducation,
  addEducation: addEducation,
  approveEducation: approveEducation,
  listAppreciation: listAppreciation,
  detailAppreciation: detailAppreciation,
  editAppreciation: editAppreciation,
  deleteAppreciation: deleteAppreciation,
  addAppreciation: addAppreciation,
  listTrainingMember: listTrainingMember,
  detailTrainingMember: detailTrainingMember,
  editTrainingMember: editTrainingMember,
  approveTrainingMember: approveTrainingMember,
  deleteTrainingMember: deleteTrainingMember,
  addTrainingMember: addTrainingMember,
  listExperienceMember: listExperienceMember,
  detailExperienceMember: detailExperienceMember,
  editExperienceMember: editExperienceMember,
  approveExperienceMember: approveExperienceMember,
  addExperienceMember: addExperienceMember,
  listSerkom: listSerkom,
  detailSerkom: detailSerkom,
  editSerkom: editSerkom,
  approveSerkom: approveSerkom,
  deleteSerkom: deleteSerkom,
  addSerkom: addSerkom,

  listSerkomAdmin: listSerkomAdmin,
  detailSerkomAdmin: detailSerkomAdmin,
  editSerkomAdmin: editSerkomAdmin,
  deleteSerkomAdmin: deleteSerkomAdmin,
  addSerkomAdmin: addSerkomAdmin,
  approveSerkomAdmin: editSerkomAdmin,

  listMutation,
  detailMutation,
  editMutation,
  addMutation,
  approveMutation,
  uploadMutation,
  uploadEducation,

  listPPDS,
  listPPDSGrafik,
  listPPDSAcceptance,
  listPPDSGO,
  listPPDSAdmin,
  addPPDS,
  detailPPDS,
  editPPDS,
  approvePPDS,
  uploadPPDS,

  listSupport,
  detailSupport,
  editSupport,
  addSupport,
  approveSupport,
  listReport,
  masterReport,
  listTransfer,
  importReport,

  userList: userList,
  editUser: editUser,
  addUser: addUser,
  deleteUser: deleteUser,
  roleList: roleList,
  listHospital: listHospital,
  addHospital: addHospital,
  deleteHospital: deleteHospital,
  editHospital: editHospital,
  detailHospital: detailHospital,
  listMembership: listMembership,
  addMembership: addMembership,
  deleteMembership: deleteMembership,
  editMembership: editMembership,
  detailMembership: detailMembership,
  selectedMembership: selectedMembership,
  changeMemberStatus: changeMemberStatus,
  trainingList: trainingList,
  editTraining: editTraining,
  deleteTraining: deleteTraining,
  addTraining: addTraining,
  experienceList: experienceList,
  editExperience: editExperience,
  deleteExperience: deleteExperience,
  addExperience: addExperience,
  listPractice: listPractice,
  editPractice: editPractice,
  addPractice: addPractice,

  detailPractice: detailPractice,
  memberRegister: memberRegister,
  changeStatusPractice: changeStatusPractice,
  memberVerifyPassword: memberVerifyPassword,
  memberForgotPassword: memberForgotPassword,
  userChangePassword: userChangePassword,
  changeImageProfile: changeImageProfile,
  p2kbList: p2kbList,
  addP2kb: addP2kb,
  uploadP2kb: uploadP2kb,
  approvalP2kb: approvalP2kb,
  approvalAllP2kb: approvalAllP2kb,
  submitAllP2kb: submitAllP2kb,
  editDetailP2kb: editDetailP2kb,
  rejectDetailP2kb: rejectDetailP2kb,
  dateP2kb: dateP2kb,
  selectedSKP: selectedSKP,
  p2kbListMember: p2kbListMember,
  realmActivityList: realmActivityList,
  listSubspesialis: listSubspesialis,
  addSubspesialis: addSubspesialis,
  deleteSubspesialis: deleteSubspesialis,
  editSubspesialis: editSubspesialis,
  detailSubspesialis: detailSubspesialis,
  editP2kb: editP2kb,
  deleteP2kb: deleteP2kb,
  detailP2kb: detailP2kb,
  listStr: listStr,
  addStr: addStr,
  deleteStr: deleteStr,
  editStr: editStr,
  detailStr: detailStr,
  approveStr: approveStr,
  listSip: listSip,
  addSip: addSip,
  deleteSip: deleteSip,
  editSip: editSip,
  detailSip: detailSip,
  approveSip: approveSip,
  addEvent: addEvent,
  listEvent: listEvent,
  detailEvent: detailEvent,
  approveEvent: approveEvent,
  deleteEvent: deleteEvent,
  editEvent: editEvent,
  selectedEvent: selectedEvent,
  listJournal: listJournal,
  detailJournal: detailJournal,
  approvalJournal: approvalJournal,
  editJournal: editJournal,
  selectedJournal: selectedJournal,
  rejectApprovalJournal: rejectApprovalJournal,
  publishJournal: publishJournal,
  revisionJournal: revisionJournal,
  listIuran: listIuran,
  detailIuran: detailIuran,
  approvalIuran: approvalIuran,
  addIuran: addIuran,
  selectedIuran: selectedIuran,
  listKoperasi: listKoperasi,
  detailKoperasi: detailKoperasi,
  approvalKoperasi: approvalKoperasi,
  addKoperasi: addKoperasi,
  selectedKoperasi: selectedKoperasi,
  findSHU: findSHUKoperasi,
  updateSHU: updateSHUKoperasi,
  mainDeposit: mainDeposit,
  listBenefit: listBenefit,
  detailBenefit: detailBenefit,
  approvalBenefit: approvalBenefit,
  addBenefit: addBenefit,
  selectedBenefit: selectedBenefit,
  uploadBenefit: uploadBenefit,
  editBenefit: editBenefit,
  listMasterBenefit: listMasterBenefit,
  detailMasterBenefit: detailMasterBenefit,
  editMasterBenefit: editMasterBenefit,
  addMasterBenefit: addMasterBenefit,
  selectedMasterBenefit: selectedMasterBenefit,
  deleteMasterBenefit: deleteMasterBenefit,
  uploadBerkas: uploadBerkas,
  memberChangePassword: memberChangePassword,
  // deleteBenefit: deleteBenefit,
  memberSerkom,
  transrsList,
  addTransrs,
  editTransrs,
  selectedTransrs,
  detailTransrs,
  approvalTransrs,
  uploadTransrs,
  SIPList,
  addSIP,
  editSIP,
  selectedSIP,
  detailSIP,
  approvalSIP,
  changeSipType,
  uploadSIP,
  branchmanList,
  addBranchman,
  listStrKT,
  addStrKT,
  editStrKT,
  detailStrKT,
  approveStrKT,
  listCandidate,
  listGraduated,
  listOrthopaedi,
  listSubspecialist,
  listTotalMember,
  listTotalFeeIn,
  listEstimationFee,
  listAllocation,
  listSubspecialist_cabang,
  listTotalMember_pusat,
  listTotalFeeIn_pusat,
  listEstimationFee_pusat,
  listAllocation_pusat,
  listSubspecialist_pusat,
  listCooperative,
  listMainDeposit,
  listRequiredDeposit,
  listSHU,
  reportSHU,
  messageBlastList,
  addMessageBlast,
  selectedMessageBlast,
  detailMessageBlast,
  uploadMessageBlast,
  listCooperative_pusat,
  listTotalMember_pusat_cabang,
  listTotalMemberDetail,
  listAdvertisement,
  listActiveAdvertisement,
  detailAdvertisement,
  addAdvertisement,
  selectedAdvertisement,
  editAdvertisement,
  deleteAdvertisement,
  listAuditTrail,
  setSelectedHeader,
  setSelectedLinkHeader,
  listFinalPaper,
  detailFinalPaper,
  addFinalPaper,
  selectedFinalPaper,
  editFinalPaper,
  deleteFinalPaper,
  notificationList,
  readNotification,

  //registrasi member
  registeredList,
  detailRegisteredMember,
});

export default rootReducer;
