import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Column, Row} from "simple-flexbox";
import {css, StyleSheet} from "aphrodite";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import moment from "moment/moment";
import DetailSubTitleComponent from "../../components/detail/DetailSubTitleComponent";
import {
    getRegisteredList,
    getRegisteredListError,
    getRegisteredListPending
} from "../../reducers/registrasi_member/regist_member_reducer";
import {registeredList} from "../../actions/registrasi_member/regist_member_action";

class DetailProfle extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        this.props.registeredList({id: id})
    }

    render() {
        const {registeredList_data} = this.props;
        const data = registeredList_data?.data[0];
        return (
            <Row className={css(styles.rightDetailContainer)}>
                <Column className={css(styles.rightDetailColumn)}>
                            <DetailTitleComponent title="Profil"/>
                            <DetailSubTitleComponent title="Data Diri"/>
                            <DetailContentComponent label="Nama Lengkap" value={data?.name}/>
                            <DetailContentComponent label="Gelar Depan" value={data?.other_front_title}/>
                            <DetailContentComponent label="Gelar Belakang" value={data?.other_back_title}/>
                            <DetailContentComponent label="Email" value={data?.email}/>
                            <DetailContentComponent label="NPA IDI" value={data?.npa_idi}/>
                            <DetailContentComponent label="NIK" value={data?.nik}/>
                            <DetailContentComponent
                                label="Tempat Lahir"
                                value={data?.place_of_birth}
                            />
                            <DetailContentComponent
                                label="Tanggal Lahir"
                                value={moment(data?.birthday).format("DD MMMM YYYY")}
                            />
                            <DetailContentComponent
                                label="Suku Bangsa"
                                value={data?.tribe ?? "-"}
                            />
                            <DetailContentComponent
                                label="Jenis Kelamin"
                                value={data?.gender === "0" ? "Perempuan" : "Laki-laki"}
                            />
                            <DetailContentComponent
                                label="No Telepon"
                                value={data?.phone ?? "-"}
                            />
                            <DetailContentComponent
                                label="NPWP"
                                value={data?.npwp ?? "-"}
                            />
                            <DetailContentComponent
                                label="Instagram"
                                value={data?.instagram ?? "-"}
                            />
                            <DetailSubTitleComponent title="Alamat Rumah"/>
                            <DetailContentComponent
                                label="Jalan"
                                value={data?.address ?? "-"}
                            />
                            <DetailContentComponent
                                label="Provinsi"
                                value={data?.province_data?.name ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kabupaten/Kota"
                                value={data?.city_data?.name ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kecamatan"
                                value={data?.district ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kelurahan"
                                value={data?.village ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kode Pos"
                                value={data?.post_code ?? "-"}
                            />
                            <DetailSubTitleComponent title="Alamat Korespondensi"/>
                            <DetailContentComponent
                                label="Jalan"
                                value={data?.correspondence_address ?? "-"}
                            />
                            <DetailContentComponent
                                label="Provinsi"
                                value={data?.correspondence_province_data?.name ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kabupaten/Kota"
                                value={data?.correspondence_city_data?.name ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kecamatan"
                                value={data?.correspondence_district ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kelurahan"
                                value={data?.correspondence_village ?? "-"}
                            />
                            <DetailContentComponent
                                label="Kode Pos"
                                value={data?.correspondence_post_code ?? "-"}
                            />
                </Column>
                </Row>
        );
    }
}

const styles = StyleSheet.create({
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40,
        alignItems: "center",
        justifyContent: "center",
        display: "block"
    },
    rightDetailContainer: {
        padding: 0,
        width: "100%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    leftDetailColumn: {
        justifyContent: "center",
        alignItems: "center"
    },
    changeStatusButton: {
        padding: 0,
        fontSize: 14
    },
    clickableIcon: {
        ":hover": {
            cursor: "pointer"
        }
    }
});

function mapStateToProps({ registeredList }) {
    return {
        registeredList_pending: getRegisteredListPending(registeredList),
        registeredList_data: getRegisteredList(registeredList),
        registeredList_error: getRegisteredListError(registeredList),
    };
}

export default connect(mapStateToProps, { registeredList })(
    withRouter(DetailProfle)
);